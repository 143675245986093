import ApiOptions from '~/api/api-options';
import { objectToQueryString, validateObject } from '~/helpers/url-helper';

export default {
  getPayrollGeneralSettings(queries) {
    return this.get(`/api/v1/pay/setting${objectToQueryString(queries)}`, 'user');
  },
  patchPayrollGeneralSetting(formData) {
    return this.patch(`/api/v1/pay/setting`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  // Expense
  getAllExpenseSettings(queries) {
    return this.get(`/api/v1/expenses/settings${objectToQueryString(queries)}`, 'user');
  },
  patchExpenseSetting(formData) {
    const allowed = ['key', 'value'];
    const filtered = validateObject(formData, allowed);
    return this.patch(`/api/v1/expenses/settings`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },

  // Leave Setting
  getAllLeaveSettings(queries) {
    return this.get(`/api/v1/leaves/settings${objectToQueryString(queries)}`, 'user');
  },
  patchLeaveSetting(formData) {
    const allowed = ['key', 'value'];
    const filtered = validateObject(formData, allowed);
    return this.patch(`/api/v1/leaves/settings`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },

  // Holiday Event Status List
  getAllHolidayEventStatusList() {
    return this.get(`/api/v1/holiday-event-status`, 'user');
  },

  // Holiday and Event
  getHolidayEventCalendar(queries) {
    return this.get(`/api/v1/schedules/events/calender${objectToQueryString(queries)}`, 'user');
  },

  // Holiday
  getLastHoliday() {
    return this.get(`/api/v1/schedules/holidays`, 'user');
  },
  getHolidays(queries) {
    return this.get(`/api/v1/schedules/holidays${objectToQueryString(queries)}`, 'user');
  },
  getHoliday(id) {
    return this.get(`/api/v1/schedules/holidays/${id}`, 'user');
  },
  createHoliday(formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/schedules/holidays`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateHoliday(id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/schedules/holidays/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteHoliday(id) {
    return this.delete(`/api/v1/schedules/holidays/${id}`, 'user');
  },

  // Event
  getLastEvent() {
    return this.get(`/api/v1/schedules/events`, 'user');
  },
  getEvents(queries) {
    return this.get(`/api/v1/schedules/events${objectToQueryString(queries)}`, 'user');
  },
  getEvent(id) {
    return this.get(`/api/v1/schedules/events/${id}`, 'user');
  },
  createEvent(formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/schedules/events`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateEvent(id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/schedules/events/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteEvent(id) {
    return this.delete(`/api/v1/schedules/events/${id}`, 'user');
  },

  // Company Info
  getCompanyInfo() {
    return this.get(`/api/v1/company-setting`, 'user');
  },
  updateCompanyInfo(formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.patch(`/api/v1/company-setting`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },

  changeCompanyLogo(formData) {
    return this.post(`/api/v1/company-setting/photo`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },

  // Count Approval 
  fetchCountApproval(queries) {
    return this.get(`/api/v1/approval-count${objectToQueryString(queries)}`, 'user');
  },
  getServerTime() {
    return this.get(`/api/v1/get-time-server`, 'user');
  },

  // Get Religion List
  getReligions() {
    return this.get(`/api/v1/religions`, 'user');
  },

  // Company Base
  getCompanyBases(queries) {
    return this.get(`/api/v1/offices${objectToQueryString(queries)}`, 'user');
  },
  getCompanyBaseById(id) {
    return this.get(`/api/v1/offices/${id}`, 'user');
  },
  createCompanyBase(formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/offices`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateCompanyBase(id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/offices/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateStatusCompanyBase(id) {
    return this.patch(`/api/v1/offices/${id}`, 'user');
  },
  deleteCompanyBase(id) {
    return this.delete(`/api/v1/offices/${id}`, 'user');
  },
};
