import ApiClient from '~/api';

const initialState = {
  expenses: {},
  leaves: {},
  holidayEvents: [],
  holidayEventsMeta: {},
  activeHolidayEvent: null,
  holidays: [],
  holidaysMeta: {},
  activeHoliday: null,
  events: [],
  eventsMeta: {},
  activeEvent: null,
  holidayEventStatusList: [],
  holidayEventCalendar: {
    holidays: [],
    events: [],
    calendar: [],
  },
  payrollGeneralSetting: {},
  companyInfo: [],

  companyBases: [],
  companyBasesMeta: {},
};

const getters = {
  expenses: state => state.expenses,
  leaves: state => state.leaves,
  holidayEvents: state => state.holidayEvents,
  holidayEventsMeta: state => state.holidayEventsMeta,
  activeHolidayEvent: state => state.activeHolidayEvent,

  holidays: state => state.holidays,
  holidaysMeta: state => state.holidaysMeta,
  activeHoliday: state => state.activeHoliday,

  events: state => state.events,
  eventsMeta: state => state.eventsMeta,
  activeEvent: state => state.activeEvent,

  holidayEventStatusList: state => state.holidayEventStatusList,
  holidayEventCalendar: state => state.holidayEventCalendar,

  payrollGeneralSetting: state => state.payrollGeneralSetting,
  companyInfo: state => state.companyInfo,

  companyBases: state => state.companyBases,
  companyBasesMeta: state => state.companyBasesMeta,
};

const actions = {
  async fetchPayrollGeneralSetting({ commit, dispatch }, formData) {
    try {
      const { data } = await ApiClient.v1.setting.getPayrollGeneralSettings(formData);
      let structured = {};
      data.forEach(element => {
        structured = {
          ...structured,
          [element.setting_key]: element.setting_value,
        };
      });

      commit('setPayrollGeneralSetting', structured);

      return structured;
    } catch (e) {
      commit('setPayrollGeneralSetting', {});

      const caption = 'Failed to Load Payroll General Setting';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  async patchPayrollGeneralSetting({ dispatch }, formData) {
    try {
      await ApiClient.v1.setting.patchPayrollGeneralSetting(formData);

      return { errors: null };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Save Payroll General Setting';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchAllExpenseSettings({ commit, dispatch }, formData) {
    try {
      const { data } = await ApiClient.v1.setting.getAllExpenseSettings(formData);

      commit('setExpenseSettings', data);

      return data;
    } catch (e) {
      commit('setExpenseSettings', {});

      const caption = 'Failed to Load Expense Setting';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },

  async patchExpenseSetting({ dispatch }, formData) {
    try {
      const { data } = await ApiClient.v1.setting.patchExpenseSetting(formData);
      sessionStorage.removeItem('expense_settings');
      return data;
    } catch (e) {
      const caption = 'Failed to Save Expense Setting';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  async fetchAllLeaveSettings({ commit, dispatch }, formData) {
    try {
      const { data } = await ApiClient.v1.setting.getAllLeaveSettings(formData);

      commit('setLeaveSettings', data);

      return data;
    } catch (e) {
      commit('setLeaveSettings', {});

      const caption = 'Failed to Load Leave Setting';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },

  async patchLeaveSetting({ dispatch }, formData) {
    try {
      const { data } = await ApiClient.v1.setting.patchLeaveSetting(formData);
      sessionStorage.removeItem('leave_settings');
      return data;
    } catch (e) {
      const caption = 'Failed to Save Leave Setting';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },

  // fetch Holiday Event
  async fetchHolidayEvents({ commit }, { query }) {
    try {
      let dat = [];
      let met = {};
      if (query.type === 'event') {
        const { data, meta } = await ApiClient.v1.setting.getEvents(query);
        dat = data;
        met = meta;
      } else {
        const { data, meta } = await ApiClient.v1.setting.getHolidays(query);
        dat = data;
        met = meta;
      }

      commit('setHolidayEvents', dat);
      commit('setHolidayEventsMeta', met);

      return true;
    } catch (e) {
      commit('setHolidayEvents', []);
      commit('setHolidayEventsMeta', {});

      return [];
    }
  },

  async fetchHolidayEventCalendar({ commit }, { query }) {
    try {
      const { data } = await ApiClient.v1.setting.getHolidayEventCalendar(query);
      commit('setHolidayEventCalendar', data);

      return data;
    } catch (e) {
      commit('setHolidayEventCalendar', []);

      return [];
    }
  },

  async deleteHolidayEvent({ commit, dispatch }, { type, id, idx }) {
    try {
      if (type === 'event') {
        await ApiClient.v1.setting.deleteEvent(id);
      } else {
        await ApiClient.v1.setting.deleteHoliday(id);
      }
      commit('deleteHolidayEvents', idx);

      return { errors: null };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  // Holiday
  async fetchLastHoliday({ dispatch }) {
    try {
      const { data } = await ApiClient.v1.setting.getLastHoliday();

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to load schedule information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchHolidays({ commit }, { query }) {
    try {
      const { data, meta } = await ApiClient.v1.setting.getHolidays(query);
      commit('setHolidays', data);
      commit('setHolidaysMeta', meta);

      return data;
    } catch (e) {
      commit('setHolidays', []);
      commit('setHolidaysMeta', {});

      return [];
    }
  },
  async fetchHoliday({ commit }, { id }) {
    try {
      const { data } = await ApiClient.v1.setting.getHoliday(id);
      // const { data } = { data: { id: 1,days: [] } };
      commit('setActiveHoliday', data);
    } catch (e) {
      commit('setActiveHoliday', null);
    }
  },
  async createHoliday({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.setting.createHoliday(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateHoliday({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.setting.updateHoliday(id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteHoliday({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.setting.deleteHoliday(id);
      commit('deleteHoliday', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  // Event
  async fetchLastEvent({ dispatch }) {
    try {
      const { data } = await ApiClient.v1.setting.getLastEvent();

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to load schedule information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEvents({ commit }, { query }) {
    try {
      const { data, meta } = await ApiClient.v1.setting.getEvents(query);
      commit('setEvents', data);
      commit('setEventsMeta', meta);

      return data;
    } catch (e) {
      commit('setEvents', []);
      commit('setEventsMeta', {});

      return [];
    }
  },
  async fetchEvent({ commit }, { id }) {
    try {
      const { data } = await ApiClient.v1.setting.getEvent(id);
      // const { data } = { data: { id: 1,days: [] } };
      commit('setActiveEvent', data);
    } catch (e) {
      commit('setActiveEvent', null);
    }
  },
  async createEvent({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.setting.createEvent({ events: formData });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEvent({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.setting.updateEvent(id, formData[0]);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEvent({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.setting.deleteEvent(id);
      commit('deleteEvent', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  // fetch holiday event statuses
  async fetchHolidayEventStatusList({ commit, dispatch }) {
    try {
      const { data } = await ApiClient.v1.setting.getAllHolidayEventStatusList();

      commit('setHolidayEventStatusList', data);

      return data;
    } catch (e) {
      commit('setHolidayEventStatusList', []);

      const caption = 'Failed to Load Status List';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },

  async getCompanyInfo({ commit, dispatch }) {
    try {
      const { data } = await ApiClient.v1.company.getCompanySettings();

      let obj = {};
      data.forEach(element => {
        obj = {
          ...obj,
          [element.key]: element.value,
        };
      });

      commit('setCompanyInfo', obj);

      return obj;
    } catch (e) {
      commit('setCompanyInfo', {});

      const caption = 'Failed to Load Company Info';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  async updateCompanyInfo({ _, dispatch }, formData) {
    try {
      await ApiClient.v1.setting.updateCompanyInfo(formData);

      return { errors: null };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  async changeCompanyLogo({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.setting.changeCompanyLogo(formData);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  // Count Approval 
  fetchCountApproval({ dispatch }, { query }) {
    return ApiClient.v1.setting.fetchCountApproval(query);
  },

  fetchServerTime() {
    return ApiClient.v1.setting.getServerTime();
  },
  fetchReligions() {
    return ApiClient.v1.setting.getReligions();
  },

  // Setting currency list
  async setCurrencyList({ commit, dispatch }, formData) {
    try {
      const { data } = await ApiClient.v1.currency.setCurrencyList(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Set Currency List';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },

  // Company Base
  async fetchCompanyBases({ commit }, { query }) {
    try {
      const { data, meta } = await ApiClient.v1.setting.getCompanyBases(query);
      commit('setCompanyBases', data);
      commit('setCompanyBasesMeta', meta);

      return true;
    } catch (e) {
      commit('setCompanyBases', []);
      commit('setCompanyBasesMeta', {});

      return [];
    }
  },
  getCompanyBaseById(_, id) {
    return ApiClient.v1.setting.getCompanyBaseById(id);
  },
  async createCompanyBase({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.setting.createCompanyBase(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to create company base';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateCompanyBase({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.setting.updateCompanyBase(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to update company base';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateStatusCompanyBase({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.setting.updateStatusCompanyBase(id);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to update status company base';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteCompanyBase({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.setting.deleteCompanyBase(id);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to delete company base';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const mutations = {
  setExpenseSettings(state, data) {
    state.expenses = data;
  },
  setLeaveSettings(state, data) {
    state.leaves = data;
  },
  setHolidayEventStatusList(state, data) {
    state.holidayEventStatusList = data;
  },

  // List Holiday Event
  setHolidayEventCalendar(state, data) {
    state.holidayEventCalendar = data;
  },
  setHolidayEvents(state, data) {
    state.holidayEvents = data;
  },
  setHolidayEventsMeta(state, data) {
    state.holidayEventsMeta = data;
  },
  deleteHolidayEvents(state, idx) {
    state.holidayEvents.splice(idx, 1);
  },

  // Holidays
  setHolidays(state, data) {
    state.holiday = data;
  },
  setHolidaysMeta(state, data) {
    state.holidayMeta = data;
  },
  setActiveHoliday(state, value) {
    state.activeHoliday = value;
  },
  setHoliday(state, data) {
    state.holiday.splice(0, 0, data); // Add to the beginning of the list
  },
  updateHoliday(state, { idx, data }) {
    state.holiday.splice(idx, 1, data);
  },
  deleteHoliday(state, idx) {
    state.holiday.splice(idx, 1);
  },

  // Events
  setEvents(state, data) {
    state.event = data;
  },
  setEventsMeta(state, data) {
    state.eventMeta = data;
  },
  setActiveEvent(state, value) {
    state.activeEvent = value;
  },
  setEvent(state, data) {
    state.event.splice(0, 0, data); // Add to the beginning of the list
  },
  updateEvent(state, { idx, data }) {
    state.event.splice(idx, 1, data);
  },
  deleteEvent(state, idx) {
    state.event.splice(idx, 1);
  },

  // Payroll General Setting
  setPayrollGeneralSetting(state, data) {
    state.payrollGeneralSetting = data;
  },

  // Company Info
  setCompanyInfo(state, data) {
    state.companyInfo = data;
  },

  // Company Base
  setCompanyBases(state, data) {
    state.companyBases = data;
  },
  setCompanyBasesMeta(state, data) {
    state.companyBasesMeta = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
