import ApiOptions from '~/api/api-options';
import { objectToQueryString, validateObject } from '~/helpers/url-helper';

export default {
  getActiveUser() {
    return this.get(`/api/v1/_self/employees`, 'user');
  },
  getMyEmergencyContacts(uuid) {
    return this.get(`/api/v1/employees/${uuid}/contacts`, 'user');
  },
  getMyOtherData(queries) {
    return this.get(`/api/v1/_self/employees/data${objectToQueryString(queries)}`, 'user');
  },
  getMyBankAccounts(uuid) {
    return this.get(`/api/v1/employees/${uuid}/accounts`, 'user');
  },
  changePassword(formData) {
    const allowed = ['old_password', 'password', 'password_confirmation'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/_self/employees/password`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  forgotPassword(formData) {
    const allowed = ['email'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/forgot_password`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  checkResetPasswordToken(formData) {
    const allowed = ['code'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/verify_token`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  resetPassword(formData) {
    const allowed = ['code', 'password', 'password_confirmation'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/reset_password`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  getCountries() {
    return this.get(`/api/v1/countries`, 'user');
  },
  getStatesByCountry(queries, id, cts = null) {
    return this.get(`/api/v1/countries/${id}/states${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getCitiesByCountry(queries, id, cts = null) {
    return this.get(`/api/v1/countries/${id}/cities${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
};
