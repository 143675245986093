import ApiClient from 'api/index';
import employeeActions from '~/store/employees/actions';

const initialState = {
  employees: [],
  employeesMeta: {},
  employeePayslips: [],
  employeeFinanceDocs: [],
  employeeFinanceDocMeta: {},
  activeEmployee: null,
  activeEmployeeOtherData: null,
  noks: [],
  noksMeta: {},
  activeNextOfKin: null,
  educations: [],
  educationsMeta: {},
  activeEducation: null,
  jobs: [],
  jobsMeta: {},
  activeJob: null,
  contacts: [],
  contactsMeta: {},
  activeContact: null,
  accounts: [],
  accountsMeta: {},
  activeAccount: null,
  positions: [],
  positionsMeta: {},
  activePosition: null,
  schedules: [],
  schedulesMeta: {},
  activeSchedule: null,
  customSchedules: [],
  customSchedulesMeta: {},
  activeCustomSchedule: null,
  shiftSchedules: [],
  shiftSchedulesMeta: {},
  activeShiftSchedule: null,
  salaries: [],
  salariesMeta: {},
  activeSalary: null,
  categories: [],
  activeCategory: null,
  histories: [],
  roles: [],

  exports: [],
  exportStatuses: [],
  exportMeta: {},
  exportDetails: [],
  exportHistories: [],
  exportEmployees: [],
  exportEmployeesMeta: {},

  trainings: [],
  trainingsMeta: {},

  bondings: [],
  bondingsMeta: {},

  warningTypes: [],
  warnings: [],
  warningsMeta: {},

  employeeTransfers: [],
  employeeTransfersMeta: {},
  employeeTransferStatuses: [],
  employeeTransferDetails: [],
  employeeTransferHistories: [],
  draftEmployeeTransfer: [],

  files: [],
  filesMeta: {},

  taxStatuses: [],

  workArrangements: [],
  workArrangementsMeta: {},
};

const getters = {
  employees: state => state.employees,
  employeesMeta: state => state.employeesMeta,
  activeEmployee: state => state.activeEmployee,
  employeePayslips: state => state.employeePayslips,
  employeeFinanceDocs: state => state.employeeFinanceDocs,
  employeeFinanceDocMeta: state => state.employeeFinanceDocMeta,
  activeEmployeeOtherData: state => state.activeEmployeeOtherData,
  noks: state => state.noks,
  noksMeta: state => state.noksMeta,
  activeNextOfKin: state => state.activeNextOfKin,
  educations: state => state.educations,
  educationsMeta: state => state.educationsMeta,
  activeEducation: state => state.activeEducation,
  jobs: state => state.jobs,
  jobsMeta: state => state.jobsMeta,
  activeJob: state => state.activeJob,
  contacts: state => state.contacts,
  contactsMeta: state => state.contactsMeta,
  activeContact: state => state.activeContact,
  accounts: state => state.accounts,
  accountsMeta: state => state.accountsMeta,
  activeAccount: state => state.activeAccount,
  positions: state => state.positions,
  positionsMeta: state => state.positionsMeta,
  activePosition: state => state.activePosition,
  salaries: state => state.salaries,
  salariesMeta: state => state.salariesMeta,
  activeSalary: state => state.activeSalary,
  categories: state => state.categories,
  activeCategory: state => state.activeCategory,
  schedules: state => state.schedules,
  schedulesMeta: state => state.schedulesMeta,
  activeSchedule: state => state.activeSchedule,
  customSchedules: state => state.customSchedules,
  customSchedulesMeta: state => state.customSchedulesMeta,
  activeCustomSchedule: state => state.activeCustomSchedule,
  shiftSchedules: state => state.shiftSchedules,
  shiftSchedulesMeta: state => state.shiftSchedulesMeta,
  activeShiftSchedule: state => state.activeShiftSchedule,
  histories: state => state.histories,
  roles: state => state.roles,

  trainings: state => state.trainings,
  trainingsMeta: state => state.trainingsMeta,

  bondings: state => state.bondings,
  bondingsMeta: state => state.bondingsMeta,

  warningTypes: state => state.warningTypes,
  warnings: state => state.warnings,
  warningsMeta: state => state.warningsMeta,

  exports: state => state.exports,
  exportStatuses: state => state.exportStatuses,
  exportMeta: state => state.exportMeta,
  exportHistories: state => state.exportHistories,
  exportEmployees: state => state.exportEmployees,
  exportEmployeesMeta: state => state.exportEmployeesMeta,

  employeeTransfers: state => state.employeeTransfers,
  employeeTransfersMeta: state => state.employeeTransfersMeta,
  employeeTransferStatuses: state => state.employeeTransferStatuses,
  employeeTransferDetails: state => state.employeeTransferDetails,
  employeeTransferHistories: state => state.employeeTransferHistories,
  draftEmployeeTransfer: state => state.draftEmployeeTransfer,

  files: state => state.files,
  filesMeta: state => state.filesMeta,

  taxStatuses: state => state.taxStatuses,

  workArrangements: state => state.workArrangements,
  workArrangementsMeta: state => state.workArrangementsMeta,
};

const actions = {
  ...employeeActions,
};

const mutations = {
  // Employees
  setEmployees(state, data) {
    state.employees = data;
  },
  setEmployeesMeta(state, data) {
    state.employeesMeta = data;
  },
  setActiveEmployee(state, value) {
    state.activeEmployee = value;
  },
  setActiveEmployeeOtherData(state, value) {
    state.activeEmployeeOtherData = value;
  },
  setEmployee(state, data) {
    state.employees.splice(0, 0, data); // Add to the beginning of the list
  },
  updateEmployee(state, { idx, data }) {
    state.employees.splice(idx, 1, data);
  },
  deleteEmployee(state, idx) {
    state.employees.splice(idx, 1);
  },

  // NextOfKins
  setNextOfKins(state, data) {
    state.noks = data;
  },
  setNextOfKinsMeta(state, data) {
    state.noksMeta = data;
  },
  setActiveNextOfKin(state, value) {
    state.activeNextOfKin = value;
  },
  setNextOfKin(state, data) {
    state.noks.splice(0, 0, data); // Add to the beginning of the list
  },
  updateNextOfKin(state, { idx, data }) {
    state.noks.splice(idx, 1, data);
  },
  deleteNextOfKin(state, idx) {
    state.noks.splice(idx, 1);
  },

  // Educations
  setEducations(state, data) {
    state.educations = data;
  },
  setEducationsMeta(state, data) {
    state.educationsMeta = data;
  },
  setActiveEducation(state, value) {
    state.activeEducation = value;
  },
  setEducation(state, data) {
    state.educations.splice(0, 0, data); // Add to the beginning of the list
  },
  updateEducation(state, { idx, data }) {
    state.educations.splice(idx, 1, data);
  },
  deleteEducation(state, idx) {
    state.educations.splice(idx, 1);
  },

  // Jobs
  setJobs(state, data) {
    state.jobs = data;
  },
  setJobsMeta(state, data) {
    state.jobsMeta = data;
  },
  setActiveJob(state, value) {
    state.activeJob = value;
  },
  setJob(state, data) {
    state.jobs.splice(0, 0, data); // Add to the beginning of the list
  },
  updateJob(state, { idx, data }) {
    state.jobs.splice(idx, 1, data);
  },
  deleteJob(state, idx) {
    state.jobs.splice(idx, 1);
  },

  // Emergency Contacts
  setContacts(state, data) {
    state.contacts = data;
  },
  setContactsMeta(state, data) {
    state.contactsMeta = data;
  },
  setActiveContact(state, value) {
    state.activeContact = value;
  },
  setContact(state, data) {
    state.contacts.splice(0, 0, data); // Add to the beginning of the list
    if (state.activeEmployee) state.activeEmployee.contacts.splice(0, 0, data);
  },
  updateContact(state, { idx, data }) {
    state.contacts.splice(idx, 1, data);
    if (state.activeEmployee) state.activeEmployee.contacts.splice(idx, 1, data);
  },
  deleteContact(state, idx) {
    state.contacts.splice(idx, 1);
    if (state.activeEmployee) state.activeEmployee.contacts.splice(idx, 1);
  },

  // BankAccounts
  setBankAccounts(state, data) {
    state.accounts = data;
  },
  setBankAccountsMeta(state, data) {
    state.accountsMeta = data;
  },
  setActiveBankAccount(state, value) {
    state.activeAccount = value;
  },
  setBankAccount(state, data) {
    state.accounts.splice(0, 0, data); // Add to the beginning of the list
  },
  updateBankAccount(state, { idx, data }) {
    state.accounts.splice(idx, 1, data);
  },
  deleteBankAccount(state, idx) {
    state.accounts.splice(idx, 1);
  },

  // EmployeePositions
  setEmployeePositions(state, data) {
    state.positions = data;
  },
  setEmployeePositionsMeta(state, data) {
    state.positionsMeta = data;
  },
  setActiveEmployeePosition(state, value) {
    state.activePosition = value;
  },
  setEmployeePosition(state, data) {
    state.positions.splice(0, 0, data); // Add to the beginning of the list
  },
  updateEmployeePosition(state, { idx, data }) {
    state.positions.splice(idx, 1, data);
  },
  deleteEmployeePosition(state, idx) {
    state.positions.splice(idx, 1);
  },

  // EmployeeSalaries
  setEmployeeSalaries(state, data) {
    state.salaries = data;
  },
  setEmployeeSalariesMeta(state, data) {
    state.salariesMeta = data;
  },
  setActiveEmployeeSalary(state, value) {
    state.activeSalary = value;
  },
  setEmployeeSalary(state, data) {
    state.salaries.splice(0, 0, data); // Add to the beginning of the list
  },
  updateEmployeeSalary(state, { idx, data }) {
    state.salaries.splice(idx, 1, data);
  },
  deleteEmployeeSalary(state, idx) {
    state.salaries.splice(idx, 1);
  },

  // DataCategories
  setDataCategories(state, data) {
    state.positions = data;
  },
  setActiveDataCategory(state, value) {
    state.activePosition = value;
  },
  setDataCategory(state, data) {
    state.positions.splice(0, 0, data); // Add to the beginning of the list
  },
  updateDataCategory(state, { idx, data }) {
    state.positions.splice(idx, 1, data);
  },
  deleteDataCategory(state, idx) {
    state.positions.splice(idx, 1);
  },

  // EmployeeSchedules
  setEmployeeSchedules(state, data) {
    state.schedules = data;
  },
  setEmployeeSchedulesMeta(state, data) {
    state.schedulesMeta = data;
  },
  setActiveEmployeeSchedule(state, value) {
    state.activeSchedule = value;
  },
  setEmployeeSchedule(state, data) {
    state.schedules.splice(0, 0, data); // Add to the beginning of the list
  },
  updateEmployeeSchedule(state, { idx, data }) {
    state.schedules.splice(idx, 1, data);
  },
  deleteEmployeeSchedule(state, idx) {
    state.schedules.splice(idx, 1);
  },

  // EmployeeCustomSchedules
  setEmployeeCustomSchedules(state, data) {
    state.customSchedules = data;
  },
  setEmployeeCustomSchedulesMeta(state, data) {
    state.customSchedulesMeta = data;
  },
  setActiveEmployeeCustomSchedule(state, value) {
    state.activeCustomSchedule = value;
  },
  setEmployeeCustomSchedule(state, data) {
    state.customSchedules.splice(0, 0, data); // Add to the beginning of the list
  },
  updateEmployeeCustomSchedule(state, { idx, data }) {
    state.customSchedules.splice(idx, 1, data);
  },
  deleteEmployeeCustomSchedule(state, idx) {
    state.customSchedules.splice(idx, 1);
  },

  // EmployeeShiftSchedules
  setEmployeeShiftSchedules(state, data) {
    state.shiftSchedules = data;
  },
  setEmployeeShiftSchedulesMeta(state, data) {
    state.shiftSchedulesMeta = data;
  },
  setActiveEmployeeShiftSchedule(state, value) {
    state.activeShiftSchedule = value;
  },
  setEmployeeShiftSchedule(state, data) {
    state.shiftSchedules.splice(0, 0, data); // Add to the beginning of the list
  },
  updateEmployeeShiftSchedule(state, { idx, data }) {
    state.shiftSchedules.splice(idx, 1, data);
  },
  deleteEmployeeShiftSchedule(state, idx) {
    state.shiftSchedules.splice(idx, 1);
  },

  // EmployeePayslip
  setEmployeePayslips(state, data) {
    state.employeePayslips = data;
  },

  // Employee Finance Doc
  setEmployeeFinanceDocs(state, data) {
    state.employeeFinanceDocs = data;
  },
  setEmployeeFinanceDocMeta(state, data) {
    state.employeeFinanceDocMeta = data;
  },

  // Employee Activate & History
  setEmployeeHistories(state, data) {
    state.histories = data;
  },

  // Employee Roles
  setEmployeeRoles(state, data) {
    state.roles = data;
  },

  // Export Employee
  setExports(state, data) {
    state.exports = data;
  },
  setExportStatuses(state, data) {
    state.exportStatuses = data;
  },
  setExportMeta(state, data) {
    state.exportMeta = data;
  },
  setExportHistories(state, data) {
    state.exportHistories = data;
  },
  setExportEmployees(state, data) {
    state.exportEmployees = data;
  },
  setExportEmployeesMeta(state, data) {
    state.exportEmployeesMeta = data;
  },

  // Employee Training & Certification
  setEmployeeTrainings(state, data) {
    state.trainings = data;
  },
  setEmployeeTrainingsMeta(state, data) {
    state.trainingsMeta = data;
  },

  // Employee Bonding Contract
  setEmployeeBondingContracts(state, data) {
    state.bondings = data;
  },
  setEmployeeBondingContractsMeta(state, data) {
    state.bondingsMeta = data;
  },

  // Employee Warning
  setWarningTypes(state, data) {
    state.warningTypes = data;
  },
  setEmployeeWarnings(state, data) {
    state.warnings = data;
  },
  setEmployeeWarningsMeta(state, data) {
    state.warningsMeta = data;
  },

  // Employee Transfer
  setEmployeeTransfers(state, data) {
    state.employeeTransfers = data;
  },
  setEmployeeTransfersMeta(state, data) {
    state.employeeTransfersMeta = data;
  },
  setEmployeeTransferStatuses(state, data) {
    state.employeeTransferStatuses = data;
  },
  setEmployeeTransferDetails(state, data) {
    state.employeeTransferDetails = data;
  },
  setEmployeeTransferHistories(state, data) {
    state.employeeTransferHistories = data;
  },
  setDraftEmployeeTransfer(state, data) {
    state.draftEmployeeTransfer = data;
  },

  setEmployeeFiles(state, data) {
    state.files = data;
  },
  setEmployeeFilesMeta(state, data) {
    state.filesMeta = data;
  },

  setTaxStatuses(state, data) {
    state.taxStatuses = data;
  },

  setWorkArrangements(state, data) {
    state.workArrangements = data;
  },
  setWorkArrangementsMeta(state, data) {
    state.workArrangementsMeta = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
