import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'adm_emp_index' */ '~/pages/admin/employee/Index');
const List = () => import(/* webpackChunkName: 'adm_emp_list' */ '~/pages/admin/employee/List');
const Create = () => import(/* webpackChunkName: 'adm_emp_create' */ '~/pages/admin/employee/Create');

const DetailIndex = () => import(/* webpackChunkName: 'admin_employee_detail_index' */ '~/pages/admin/employee/detail/Index');
const DetailDashboard = () => import(/* webpackChunkName: 'admin_employee_detail_dashboard' */ '~/pages/admin/employee/detail/Dashboard');

const DetailPersonalIndex = () => import(/* webpackChunkName: 'admin_employee_detail_personal' */ '~/pages/admin/employee/detail/personal/Index');
const DetailPersonalList = () => import(/* webpackChunkName: 'admin_employee_detail_personal' */ '~/pages/admin/employee/detail/personal/List');
const DetailPersonalForm = () => import(/* webpackChunkName: 'admin_employee_detail_personal' */ '~/pages/admin/employee/detail/personal/Form');

const DetailFamilyIndex = () => import(/* webpackChunkName: 'admin_employee_detail_family_index' */ '~/pages/admin/employee/detail/family/Index');
const DetailFamilyList = () => import(/* webpackChunkName: 'admin_employee_detail_family_list' */ '~/pages/admin/employee/detail/family/List');
const DetailFamilyCreate = () => import(/* webpackChunkName: 'admin_employee_detail_family_create' */ '~/pages/admin/employee/detail/family/Create');
const DetailFamilyUpdate = () => import(/* webpackChunkName: 'admin_employee_detail_family_update' */ '~/pages/admin/employee/detail/family/Update');

const DetailEducationIndex = () =>
  import(/* webpackChunkName: 'admin_employee_detail_education_index' */ '~/pages/admin/employee/detail/education/Index');
const DetailEducationList = () =>
  import(/* webpackChunkName: 'admin_employee_detail_education_list' */ '~/pages/admin/employee/detail/education/List');
const DetailEducationCreate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_education_create' */ '~/pages/admin/employee/detail/education/Create');
const DetailEducationUpdate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_education_update' */ '~/pages/admin/employee/detail/education/Update');

const DetailJobIndex = () => import(/* webpackChunkName: 'admin_employee_detail_job_index' */ '~/pages/admin/employee/detail/job/Index');
const DetailJobList = () => import(/* webpackChunkName: 'admin_employee_detail_job_list' */ '~/pages/admin/employee/detail/job/List');
const DetailJobCreate = () => import(/* webpackChunkName: 'admin_employee_detail_job_create' */ '~/pages/admin/employee/detail/job/Create');
const DetailJobUpdate = () => import(/* webpackChunkName: 'admin_employee_detail_job_update' */ '~/pages/admin/employee/detail/job/Update');

const DetailFinanceIndex = () => import(/* webpackChunkName: 'admin_employee_detail_finance_index' */ '~/pages/admin/employee/detail/finance/Index');
const DetailFinanceList = () => import(/* webpackChunkName: 'admin_employee_detail_finance_list' */ '~/pages/admin/employee/detail/finance/List');
const DetailFinanceUpdate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_finance_update' */ '~/pages/admin/employee/detail/finance/Update');

const DetailPositionIndex = () =>
  import(/* webpackChunkName: 'admin_employee_detail_position_index' */ '~/pages/admin/employee/detail/position/Index');
const DetailPositionList = () => import(/* webpackChunkName: 'admin_employee_detail_position_list' */ '~/pages/admin/employee/detail/position/List');
const DetailPositionCreate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_position_create' */ '~/pages/admin/employee/detail/position/Create');
const DetailPositionUpdate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_position_update' */ '~/pages/admin/employee/detail/position/Update');

const DetailPayrollIndex = () => import(/* webpackChunkName: 'admin_employee_detail_payroll_index' */ '~/pages/admin/employee/detail/payroll/Index');
const DetailPayrollList = () => import(/* webpackChunkName: 'admin_employee_detail_payroll_list' */ '~/pages/admin/employee/detail/payroll/List');
const DetailPayrollCreate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_payroll_create' */ '~/pages/admin/employee/detail/payroll/Create');
const DetailPayrollUpdate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_payroll_update' */ '~/pages/admin/employee/detail/payroll/Update');
const DetailPayrollView = () => import(/* webpackChunkName: 'admin_employee_detail_payroll_view' */ '~/pages/admin/employee/detail/payroll/View');
const DetailPayrollRevise = () =>
  import(/* webpackChunkName: 'admin_employee_detail_payroll_revise' */ '~/pages/admin/employee/detail/payroll/Revise');
const DetailPayrollHistory = () =>
  import(/* webpackChunkName: 'admin_employee_detail_payroll_history' */ '~/pages/admin/employee/detail/payroll/History');

const DetailFile = () => import('~/pages/admin/employee/detail/File');

const DetailAccessIndex = () => import(/* webpackChunkName: 'admin_employee_detail_access_index' */ '~/pages/admin/employee/detail/access/Index');
const DetailAccessRoleIndex = () =>
  import(/* webpackChunkName: 'admin_employee_detail_access_role_index' */ '~/pages/admin/employee/detail/access/role/Index');
const DetailAccessRoleList = () =>
  import(/* webpackChunkName: 'admin_employee_detail_access_role_list' */ '~/pages/admin/employee/detail/access/role/List');
const DetailAccessRoleView = () =>
  import(/* webpackChunkName: 'admin_employee_detail_access_role_view' */ '~/pages/admin/employee/detail/access/role/View');
const DetailAccessPermissionIndex = () =>
  import(/* webpackChunkName: 'admin_employee_detail_access_permission_index' */ '~/pages/admin/employee/detail/access/permission/Index');
const DetailAccessPermissionList = () =>
  import(/* webpackChunkName: 'admin_employee_detail_access_permission_list' */ '~/pages/admin/employee/detail/access/permission/List');

const DetailScheduleIndex = () => import(/* webpackChunkName: 'admin_employee_detail_schedule' */ '~/pages/admin/employee/detail/schedule/Index');
const DetailScheduleList = () => import(/* webpackChunkName: 'admin_employee_detail_schedule' */ '~/pages/admin/employee/detail/schedule/List');
const DetailScheduleCreate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_schedule_create' */ '~/pages/admin/employee/detail/schedule/Create');
const DetailScheduleUpdate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_schedule_update' */ '~/pages/admin/employee/detail/schedule/Update');
const DetailScheduleView = () => import(/* webpackChunkName: 'admin_employee_detail_schedule_view' */ '~/pages/admin/employee/detail/schedule/View');

const DetailScheduleCustomIndex = () => import(/* webpackChunkName: 'admin_employee_detail_schedule_custom' */ '~/pages/admin/employee/detail/schedule/custom/Index');
const DetailScheduleCustomCreate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_schedule_custom_create' */ '~/pages/admin/employee/detail/schedule/custom/Create');
const DetailScheduleCustomUpdate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_schedule_custom_update' */ '~/pages/admin/employee/detail/schedule/custom/Update');

const DetailScheduleShiftIndex = () => import(/* webpackChunkName: 'admin_employee_detail_schedule_shift' */ '~/pages/admin/employee/detail/schedule/shift/Index');
const DetailScheduleShiftCreate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_schedule_shift_create' */ '~/pages/admin/employee/detail/schedule/shift/Create');
const DetailScheduleShiftUpdate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_schedule_shift_update' */ '~/pages/admin/employee/detail/schedule/shift/Update');

const ExportIndex = () => import(/* webpackChunkName: 'admin_employee_export_index' */ '~/pages/admin/employee/ExportIndex');
const ExportList = () => import(/* webpackChunkName: 'admin_employee_export_list' */ '~/pages/admin/employee/ExportList');
const ExportDetail = () => import(/* webpackChunkName: 'admin_employee_export_detail' */ '~/pages/admin/employee/ExportDetail');

const DetailTrainingIndex = () =>
  import(/* webpackChunkName: 'admin_employee_detail_training_index' */ '~/pages/admin/employee/detail/training/Index');
const DetailTrainingList = () => import(/* webpackChunkName: 'admin_employee_detail_training_list' */ '~/pages/admin/employee/detail/training/List');
const DetailTrainingCreate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_training_create' */ '~/pages/admin/employee/detail/training/Create');
const DetailTrainingUpdate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_training_update' */ '~/pages/admin/employee/detail/training/Update');

const DetailBondingIndex = () =>
  import(/* webpackChunkName: 'admin_employee_detail_bonding_index' */ '~/pages/admin/employee/detail/bonding-contract/Index');
const DetailBondingList = () => import(/* webpackChunkName: 'admin_employee_detail_bonding_list' */ '~/pages/admin/employee/detail/bonding-contract/List');
const DetailBondingCreate = () => import(/* webpackChunkName: 'admin_employee_detail_bonding_create' */ '~/pages/admin/employee/detail/bonding-contract/Create');
const DetailBondingUpdate = () => import(/* webpackChunkName: 'admin_employee_detail_bonding_update' */ '~/pages/admin/employee/detail/bonding-contract/Update');

const WarningTypeIndex = () => import(/* webpackChunkName: 'admin_warning_type_index' */ '~/pages/admin/employee/WarningIndex');
const WarningTypeList = () => import(/* webpackChunkName: 'admin_warning_type_list' */ '~/pages/admin/employee/WarningList');
const WarningTypeCreate = () => import(/* webpackChunkName: 'admin_warning_type_create' */ '~/pages/admin/employee/WarningCreate');
const WarningTypeUpdate = () => import(/* webpackChunkName: 'admin_warning_type_update' */ '~/pages/admin/employee/WarningUpdate');

const DetailWarningIndex = () =>
  import(/* webpackChunkName: 'admin_employee_detail_warning_index' */ '~/pages/admin/employee/detail/warning/Index');
const DetailWarningList = () => import(/* webpackChunkName: 'admin_employee_detail_warning_list' */ '~/pages/admin/employee/detail/warning/List');
const DetailWarningCreate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_warning_create' */ '~/pages/admin/employee/detail/warning/Create');
const DetailWarningUpdate = () =>
  import(/* webpackChunkName: 'admin_employee_detail_warning_update' */ '~/pages/admin/employee/detail/warning/Update');

const EmployeeTransferIndex = () => import(/* webpackChunkName: 'admin_employee_transfer_index' */ '~/pages/admin/employee/EmployeeTransferIndex');
const EmployeeTransferList = () => import(/* webpackChunkName: 'admin_employee_transfer_list' */ '~/pages/admin/employee/EmployeeTransferList');
const EmployeeTransferDetail = () => import(/* webpackChunkName: 'admin_employee_transfer_detail' */ '~/pages/admin/employee/EmployeeTransferDetail');
const EmployeeTransferCreate = () => import(/* webpackChunkName: 'admin_employee_transfer_create' */ '~/pages/admin/employee/EmployeeTransferCreate');

const WorkArrangementIndex = () => import(/* webpackChunkName: 'admin_employee_work_arrangement_index' */ '~/pages/admin/employee/WorkArrangementIndex');
const WorkArrangementList = () => import(/* webpackChunkName: 'admin_employee_work_arrangement_list' */ '~/pages/admin/employee/WorkArrangementList');
const WorkArrangementCreate = () => import(/* webpackChunkName: 'admin_employee_work_arrangement_create' */ '~/pages/admin/employee/WorkArrangementCreate');
const WorkArrangementUpdate = () => import(/* webpackChunkName: 'admin_employee_work_arrangement_update' */ '~/pages/admin/employee/WorkArrangementUpdate');

export default {
  path: 'employee',
  component: r(Index),
  children: [
    { path: '', redirect: '/admin/employee/list', name: 'admin_employee_old_list' },
    {
      path: 'list',
      component: r(List),
      name: 'admin_employee_list',
    },
    {
      path: 'new',
      component: r(Create),
      name: 'admin_employee_create',
    },
    {
      path: 'export',
      component: r(ExportIndex),
      children: [
        { path: '', name: 'admin_employee_export_list', component: r(ExportList) },
        { path: ':id', name: 'admin_employee_export_detail', component: r(ExportDetail) },
      ],
    },
    {
      path: 'warning',
      component: r(WarningTypeIndex),
      children: [
        { path: '', name: 'admin_employee_warning_list', component: r(WarningTypeList) },
        { path: 'new', component: r(WarningTypeCreate), name: 'admin_employee_warning_create' },
        { path: ':id(\\d+)', component: r(WarningTypeUpdate), name: 'admin_employee_warning_update' },
      ],
    },
    {
      path: 'transfer',
      component: r(EmployeeTransferIndex),
      children: [
        { path: '', name: 'admin_employee_transfer_list', component: r(EmployeeTransferList) },
        { path: ':id/new', name: 'admin_employee_transfer_create', component: r(EmployeeTransferCreate) },
        { path: ':id(\\d+)', component: r(EmployeeTransferDetail), name: 'admin_employee_transfer_detail' },
      ],
    },
    {
      path: 'work-arrangement',
      component: r(WorkArrangementIndex),
      children: [
        {
          path: '',
          name: 'admin_employee_work_arrangement_list',
          component: r(WorkArrangementList),
        },
        {
          path: 'new',
          name: 'admin_employee_work_arrangement_create',
          component: r(WorkArrangementCreate),
        },
        {
          path: ':fid',
          name: 'admin_employee_work_arrangement_update',
          component: r(WorkArrangementUpdate),
        },
      ],
    },
    {
      path: ':id',
      component: r(DetailIndex),
      children: [
        {
          path: '',
          name: 'admin_employee_detail_placeholder',
          redirect: '/admin/employee/:id/personal',
        },
        {
          path: 'dashboard',
          name: 'admin_employee_detail_dashboard',
          component: r(DetailDashboard),
        },
        {
          path: 'personal',
          component: r(DetailPersonalIndex),
          children: [
            {
              path: '/',
              name: 'admin_employee_detail_personal_list',
              component: r(DetailPersonalList),
            },
            {
              path: 'form',
              name: 'admin_employee_detail_personal_form',
              component: r(DetailPersonalForm),
            },
          ],
        },
        {
          path: 'family',
          component: r(DetailFamilyIndex),
          children: [
            {
              path: '',
              name: 'admin_employee_detail_family_list',
              component: r(DetailFamilyList),
            },
            {
              path: 'new',
              name: 'admin_employee_detail_family_create',
              component: r(DetailFamilyCreate),
            },
            {
              path: ':fid',
              name: 'admin_employee_detail_family_update',
              component: r(DetailFamilyUpdate),
            },
          ],
        },
        {
          path: 'education',
          component: r(DetailEducationIndex),
          children: [
            {
              path: '',
              name: 'admin_employee_detail_education_list',
              component: r(DetailEducationList),
            },
            {
              path: 'new',
              name: 'admin_employee_detail_education_create',
              component: r(DetailEducationCreate),
            },
            {
              path: ':fid',
              name: 'admin_employee_detail_education_update',
              component: r(DetailEducationUpdate),
            },
          ],
        },
        {
          path: 'job',
          component: r(DetailJobIndex),
          children: [
            {
              path: '',
              name: 'admin_employee_detail_job_list',
              component: r(DetailJobList),
            },
            {
              path: 'new',
              name: 'admin_employee_detail_job_create',
              component: r(DetailJobCreate),
            },
            {
              path: ':fid',
              name: 'admin_employee_detail_job_update',
              component: r(DetailJobUpdate),
            },
          ],
        },
        {
          path: 'finance',
          component: r(DetailFinanceIndex),
          children: [
            {
              path: '',
              name: 'admin_employee_detail_finance_list',
              component: r(DetailFinanceList),
            },
            {
              path: 'form',
              name: 'admin_employee_detail_finance_update',
              component: r(DetailFinanceUpdate),
            },
          ],
        },
        {
          path: 'position',
          component: r(DetailPositionIndex),
          children: [
            {
              path: '',
              name: 'admin_employee_detail_position_list',
              component: r(DetailPositionList),
            },
            {
              path: 'new',
              name: 'admin_employee_detail_position_create',
              component: r(DetailPositionCreate),
            },
            {
              path: ':fid',
              name: 'admin_employee_detail_position_update',
              component: r(DetailPositionUpdate),
            },
          ],
        },
        {
          path: 'payroll',
          component: r(DetailPayrollIndex),
          children: [
            {
              path: '',
              name: 'admin_employee_detail_payroll_list',
              component: r(DetailPayrollList),
            },
            {
              path: 'new',
              name: 'admin_employee_detail_payroll_create',
              component: r(DetailPayrollCreate),
            },
            {
              path: ':fid',
              name: 'admin_employee_detail_payroll_update',
              component: r(DetailPayrollUpdate),
            },
            {
              path: 'revise/:fid',
              name: 'admin_employee_detail_payroll_revise',
              component: r(DetailPayrollRevise),
            },
            {
              path: 'view/:fid',
              name: 'admin_employee_detail_payroll_view',
              component: r(DetailPayrollView),
            },
            {
              path: 'history/:fid',
              name: 'admin_employee_detail_payroll_history',
              component: r(DetailPayrollHistory),
            },
          ],
        },
        {
          path: 'file',
          name: 'admin_employee_detail_file',
          component: r(DetailFile),
        },
        {
          path: 'access',
          component: r(DetailAccessIndex),
          name: 'admin_employee_detail_access',
          redirect: 'access/role',
          children: [
            {
              path: 'role',
              component: r(DetailAccessRoleIndex),
              children: [
                {
                  path: '',
                  name: 'admin_employee_detail_access_role_list',
                  component: r(DetailAccessRoleList),
                },
                {
                  path: 'view/:fid',
                  name: 'admin_employee_detail_access_role_view',
                  component: r(DetailAccessRoleView),
                },
              ],
            },
            {
              path: 'permission',
              component: r(DetailAccessPermissionIndex),
              children: [
                {
                  path: '',
                  name: 'admin_employee_detail_access_permission_list',
                  component: r(DetailAccessPermissionList),
                },
              ],
            },
          ],
        },
        {
          path: 'schedule',
          component: r(DetailScheduleIndex),
          children: [
            {
              path: '',
              name: 'admin_employee_detail_schedule_list',
              component: r(DetailScheduleList),
            },
            {
              path: 'new',
              name: 'admin_employee_detail_schedule_create',
              component: r(DetailScheduleCreate),
            },
            {
              path: ':fid',
              name: 'admin_employee_detail_schedule_update',
              component: r(DetailScheduleUpdate),
            },
            {
              path: 'view/:fid',
              name: 'admin_employee_detail_schedule_view',
              component: r(DetailScheduleView),
            },
            {
              path: 'view/:fid/custom',
              component: r(DetailScheduleCustomIndex),
              children: [
                {
                  path: 'new',
                  name: 'admin_employee_detail_schedule_custom_create',
                  component: r(DetailScheduleCustomCreate),
                },
                {
                  path: ':cid',
                  name: 'admin_employee_detail_schedule_custom_update',
                  component: r(DetailScheduleCustomUpdate),
                },
              ],
            },
            {
              path: 'view/:fid/shift',
              component: r(DetailScheduleShiftIndex),
              children: [
                {
                  path: 'new',
                  name: 'admin_employee_detail_schedule_shift_create',
                  component: r(DetailScheduleShiftCreate),
                },
                {
                  path: ':cid',
                  name: 'admin_employee_detail_schedule_shift_update',
                  component: r(DetailScheduleShiftUpdate),
                },
              ],
            },
          ],
        },
        {
          path: 'training-certification',
          component: r(DetailTrainingIndex),
          children: [
            {
              path: '',
              name: 'admin_employee_detail_training_list',
              component: r(DetailTrainingList),
            },
            {
              path: 'new',
              name: 'admin_employee_detail_training_create',
              component: r(DetailTrainingCreate),
            },
            {
              path: ':fid',
              name: 'admin_employee_detail_training_update',
              component: r(DetailTrainingUpdate),
            },
          ],
        },
        {
          path: 'bonding-contract',
          component: r(DetailBondingIndex),
          children: [
            {
              path: '',
              name: 'admin_employee_detail_bonding_list',
              component: r(DetailBondingList),
            },
            {
              path: 'new',
              name: 'admin_employee_detail_bonding_create',
              component: r(DetailBondingCreate),
            },
            {
              path: ':fid',
              name: 'admin_employee_detail_bonding_update',
              component: r(DetailBondingUpdate),
            },
          ],
        },
        {
          path: 'warning',
          component: r(DetailWarningIndex),
          children: [
            {
              path: '',
              name: 'admin_employee_detail_warning_list',
              component: r(DetailWarningList),
            },
            {
              path: 'new',
              name: 'admin_employee_detail_warning_create',
              component: r(DetailWarningCreate),
            },
            {
              path: ':fid',
              name: 'admin_employee_detail_warning_update',
              component: r(DetailWarningUpdate),
            },
          ],
        },
      ],
    },
  ],
};
