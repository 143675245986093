import ApiOptions from '~/api/api-options';
import { objectToQueryString, validateObject } from '~/helpers/url-helper';

export default {
  getCalendar(formData) {
    const stacks = ['start', 'end'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/employees/${formData.employeeId}/schedules/calender${filteredQs}`, 'user');
  },
  getScheduleShift(formData) {
    const stacks = ['*'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/schedules/shift${filteredQs}`, 'user');
  },
  getTypes(formData) {
    const stacks = ['*'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/schedule-types${filteredQs}`, 'user');
  },
  getSubTypes(formData) {
    const stacks = ['*'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/schedules/types/${formData.id_types.id}`, 'user');
  },
  getEmployeeTimeShift(formData) {
    const stacks = ['employee_id', 'start_date', 'end_date'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/schedules/employees/time_shift${filteredQs}`, 'user');
  },
  getUpcomingHolidays(formData) {
    const stacks = ['start_date', 'end_date', 'country'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/schedules/holidays/upcoming${filteredQs}`, 'user');
  },
  getUpcomingEvents(formData) {
    const stacks = ['start_date', 'end_date'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/schedules/events/upcoming${filteredQs}`, 'user');
  },

  // Schedule by Employee List
  getEmployees(queries) {
    return this.get(`/api/v1/employees/schedules${objectToQueryString(queries)}`, 'user');
  },

  // Work Hour
  getLastWorkHour() {
    return this.get(`/api/v1/work-hours`, 'user');
  },
  getWorkHours(queries) {
    return this.get(`/api/v1/work-hours${objectToQueryString(queries)}`, 'user');
  },
  getWorkHour(id) {
    return this.get(`/api/v1/work-hours/${id}`, 'user');
  },
  createWorkHour(formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/work-hours`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateWorkHour(id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/work-hours/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteWorkHour(id) {
    return this.delete(`/api/v1/work-hours/${id}`, 'user');
  },

  // Fixed Type
  getLastFixedType() {
    return this.get(`/api/v1/fixed/schedules`, 'user');
  },
  getFixedTypes(queries, customCompanyId = null) {
    return this.get(`/api/v1/fixed/schedules${objectToQueryString(queries)}`, 'user', new ApiOptions({ customCompanyId }));
  },
  getFixedType(id) {
    return this.get(`/api/v1/fixed/schedules/${id}`, 'user');
  },
  createFixedType(formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/fixed/schedules`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateFixedType(id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/fixed/schedules/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteFixedType(id) {
    return this.delete(`/api/v1/fixed/schedules/${id}`, 'user');
  },

  // Department Shift
  getDepartmentShifts(queries, cts = null) {
    return this.get(`/api/v1/departments/${queries.id_department}/schedules${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getDepartmentShift(queries) {
    return this.get(`/api/v1/departments/${queries.id_department}/schedules/detail${objectToQueryString(queries)}`, 'user');
  },
  createUpdateDepartmentShift(formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/departments/${formData.id_department}/schedules`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateDepartmentShift(formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/departments/${formData.id_department}/schedules/detail`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteDepartmentShift(queries) {
    return this.delete(`/api/v1/departments/${queries.id_department}/schedules/detail${objectToQueryString(queries)}`, 'user');
  },
  deleteDepartmentShiftEmployee(formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.delete(`/api/v1/departments/${formData.id_department}/schedules`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
};
