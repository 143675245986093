import ApiClient from '~/api';

const initialState = {
  countries: [],
  countriesApi: [],
  states: [],
  statesApi: [],
  cities: [],
  citiesApi: [],
  codes: [],
};

const getters = {
  countries: state => state.countries,
  countriesApi: state => state.countriesApi,
  states: state => state.states,
  statesApi: state => state.statesApi,
  cities: state => state.cities,
  citiesApi: state => state.citiesApi,
  codes: state => state.codes,
  country: state => param => {
    const r = state.countries.find(country => country.text.toLowerCase() === param.toLowerCase());
    return r || param;
  },
  state: state => (param, countryCode) => {
    const c = Object.entries(state.states).find(a => a[0] === countryCode);
    if (c[1].length > 0) {
      const r = c[1].find(s => s.text.toLowerCase() === param.toLowerCase());
      if (r !== undefined) return r;
    } 
    return param;
  },
  city: state => (param, countryCode, stateCode) => {
    try {
      const s = state.cities[countryCode][stateCode];
      const r = s.find(a => a.text.toLowerCase() === param.toLowerCase());
      if (r !== undefined) return r;
      return param;
    } catch (e) {
      return param;
    }
    
  },
};

const actions = {
  async getCountries({ commit }) {
    try {
      const url = '/data/list-country.json';
      const { data } = await this.$axios.get(url);
      commit('setCountries', data);
      return [];
    } catch (e) {
      commit('setCountries', []);
      return [];
    }
  },
  async getCountriesByApi({ commit, dispatch }) {
    try {
      const { data } = await ApiClient.v1.user.getCountries();
      commit('setCountriesApi', data);
      return data;
    } catch (e) {
      commit('setCountriesApi', []);
      const caption = 'Failed to Load Countries';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async getStates({ commit }) {
    try {
      const url = '/data/list-country-state.json';
      const { data } = await this.$axios.get(url);
      commit('setStates', data);
      return {};
    } catch (e) {
      commit('setStates', []);
      return {};
    }
  },
  async getStatesByApi({ commit, dispatch }, { query, id, cts = null }) {
    try {
      const { data } = await ApiClient.v1.user.getStatesByCountry(query, id, cts);
      commit('setStatesApi', data);
      return data;
    } catch (e) {
      commit('setStatesApi', []);
      const caption = 'Failed to Load States';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async getCities({ commit }) {
    try {
      const url = '/data/list-country-city.json';
      const { data } = await this.$axios.get(url);
      commit('setCities', data);
      return {};
    } catch (e) {
      commit('setCities', []);
      return {};
    }
  },
  async getCitiesByApi({ commit, dispatch }, { query, id, cts = null }) {
    try {
      const { data } = await ApiClient.v1.user.getCitiesByCountry(query, id, cts);
      commit('setCitiesApi', data);
      return data;
    } catch (e) {
      commit('setCitiesApi', []);
      const caption = 'Failed to Load Cities';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async getCodes({ commit }) {
    try {
      const url = '/data/list-country-code.json';
      const { data } = await this.$axios.get(url);
      commit('setCodes', data);
      return {};
    } catch (e) {
      commit('setCodes', []);
      return {};
    }
  },
};

const mutations = {
  setCountries(state, value) {
    state.countries = value;
  },
  setCountriesApi(state, value) {
    state.countriesApi = value;
  },
  setStates(state, value) {
    state.states = value;
  },
  setStatesApi(state, value) {
    state.statesApi = value;
  },
  setCities(state, value) {
    state.cities = value;
  },
  setCitiesApi(state, value) {
    state.citiesApi = value;
  },
  setCodes(state, value) {
    state.codes = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
