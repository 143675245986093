import ApiOptions from '~/api/api-options';
import { objectToQueryString, validateObject } from '~/helpers/url-helper';

export default {
  getRequests(queries, cts = null) {
    return this.get(`/api/v1/employee-requests${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getRequestById(id) {
    return this.get(`/api/v1/employee-requests/${id}`, 'user');
  },
  getRequestStatus(queries) {
    return this.get(`/api/v1/status${objectToQueryString(queries)}`, 'user');
  },
  getEmploymentStatus(queries) {
    return this.get(`/api/v1/employment-status${objectToQueryString(queries)}`, 'user');
  },
  getOfficeLocation(queries) {
    return this.get(`/api/v1/offices${objectToQueryString(queries)}`, 'user');
  },
  getDevice(queries) {
    return this.get(`/api/v1/devices${objectToQueryString(queries)}`, 'user');
  },
  getDeviceUsage(queries) {
    return this.get(`/api/v1/device-received${objectToQueryString(queries)}`, 'user');
  },
  getRequestHistoryById(id) {
    return this.get(`/api/v1/employee-requests/${id}/history`, 'user');
  },
  setRequestStatus(id, formData) {
    return this.patch(`/api/v1/employee-requests/${id}/status`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  createEmployeeRequest(formData) {
    return this.post(`/api/v1/employee-requests`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateEmployeeRequest(id, formData) {
    return this.put(`/api/v1/employee-requests/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
};
