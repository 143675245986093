import Vue from 'vue';

export function switchCompany(data) {
  const company = {};
  data.slice(0, 3).forEach((el, idx) => {
    Object.assign(company, { [idx]: el.company_name });
  });

  Vue.swal({
    title: 'Company not found',
    text: 'Company that you are trying to access has been deactivated. Please switch to another company.',
    icon: 'error',
    iconHtml: '<img class="mw-100" src="/images/company-not-found.png">',
    allowEscapeKey: false,
    allowOutsideClick: false,
    showCancelButton: false,
    confirmButtonText: 'OK',
    input: 'select',
    inputOptions: company,
    inputPlaceholder: 'Select company',
    customClass: {
      content: 'p-2',
      popup: 'swal2-popup--sm',
      icon: 'border-0 icon-big',
      confirmButton: 'swal2-confirm--sm swal2-confirm--block u-bg--blue',
    },
    inputValidator: value => {
      return new Promise(resolve => {
        if (value) {
          // console.info(data[value]);
          Vue.$cookies.set('selected_company', JSON.stringify(data[value]));
          window.location.reload();
        } else {
          resolve('Company is required');
        }
      });
    },
  });
}

export function redirectLogin() {
  Vue.swal({
    title: 'Company not found',
    text: 'Company that you are trying to access has been deactivated. You\'ll be redirected to Login page.',
    icon: 'error',
    iconHtml: '<img class="mw-100" src="/images/company-not-found.png">',
    allowEscapeKey: false,
    allowOutsideClick: false,
    showCancelButton: false,
    confirmButtonText: 'OK',
    customClass: {
      content: 'p-2',
      popup: 'swal2-popup--sm',
      icon: 'border-0 icon-big',
      confirmButton: 'swal2-confirm--sm swal2-confirm--block u-bg--red',
    },
  }).then(function() {
    window.location = '/logout';
  });
}

export function popupTransfer(redirect = true) {
  Vue.swal({
    title: 'Access Denied',
    text: 'Sorry, you are temporarily unable to log in due to an ongoing employee transfer process. Please wait for a while and then try to log in again. If the issue persists, kindly contact your HR administrator for further assistance.',
    icon: 'error',
    allowEscapeKey: false,
    allowOutsideClick: false,
    showCancelButton: false,
    confirmButtonText: 'OK',
    customClass: {
      content: 'p-2',
      popup: 'swal2-popup--sm',
      confirmButton: 'swal2-confirm--sm swal2-confirm--block u-bg--red',
    },
  }).then(function() {
    if (redirect) window.location = '/logout';
  });
}

export function getCountryCompanyBase(location) {
  if (location && location.length) {
    const data = location.filter((item, index, self) => index === self.findIndex(obj => obj.country.iso2 === item.country.iso2));
    return data.map(x => x.country);
  }

  return [];
}

export default {
  switchCompany,
  redirectLogin,
  popupTransfer,
  getCountryCompanyBase,
};
