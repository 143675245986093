import { render, staticRenderFns } from "./CSidebar.vue?vue&type=template&id=bbbb6972&scoped=true&"
import script from "./CSidebar.vue?vue&type=script&lang=js&"
export * from "./CSidebar.vue?vue&type=script&lang=js&"
import style0 from "./CSidebar.vue?vue&type=style&index=0&id=bbbb6972&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbbb6972",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CLogo: require('/home/web/alpha-fe/components/CLogo.vue').default,CSidebarGroup: require('/home/web/alpha-fe/components/navbar/CSidebarGroup.vue').default})
