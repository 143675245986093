import ApiClient from 'api/index';
import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

const initialState = {
  settings: [],
  companies: [],
  companiesMeta: {},
  selectedCompany: {},
  activeCompany: [],
};

const getters = {
  settings: state => state.settings,
  companies: state => state.companies,
  companiesMeta: state => state.companiesMeta,
  selectedCompany: state => state.selectedCompany,
  activeCompany: state => state.activeCompany,
};

const actions = {
  async fetchCompanySettings({ commit, dispatch }) {
    try {
      const lsKey = 'company_settings';
      let settings;

      if (localStorage.getItem(lsKey)) {
        try {
          settings = JSON.parse(localStorage.getItem(lsKey));
        } catch (e) {
          localStorage.removeItem(lsKey);
        }
      } else {
        const { data } = await ApiClient.v1.company.getCompanySettings();
        settings = data;
      }

      localStorage.setItem(lsKey, JSON.stringify(settings));
      commit('setSettings', settings);

      return settings;
    } catch (e) {
      commit('setSettings', []);

      const caption = 'Failed to Load Company Setting';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },

  // Manage Company
  setSelectedCompany({ commit, dispatch }, company) {
    if (company) {
      // localStorage.setItem('selected_company', JSON.stringify(company));
      Vue.$cookies.set('selected_company', JSON.stringify(company), 0);
      commit('setCompany', company);
    }
  },
  getSelectedCompany({ commit, dispatch }) {
    /* if (localStorage.getItem('selected_company')) {
      return JSON.parse(localStorage.getItem('selected_company'));
    } */
    if (Vue.$cookies.get('selected_company')) return Vue.$cookies.get('selected_company');
    return null;
  },
  async fetchCompanies({ commit, dispatch }, query) {
    try {
      const { data, meta } = await ApiClient.v1.company.getCompanies(query);
      commit('setCompanies', data);
      commit('setCompaniesMeta', meta);

      return data;
    } catch (e) {
      commit('setCompanies', []);
      commit('setCompaniesMeta', {});

      const caption = 'Unable to load companies list';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  async fetchCompanyByEmployee({ commit, dispatch }, { uuid, query }) {
    try {
      const { data } = await ApiClient.v1.company.getCompanyByEmployee(uuid, query);
      commit('setActiveCompany', data);

      return data;
    } catch (e) {
      commit('setActiveCompany', []);

      const caption = 'Unable to load companies list';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  fetchCompanyById(_, id) {
    return ApiClient.v1.company.getCompanyById(id);
  },
  async createCompany({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.company.createCompany(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to create company';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateCompany({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.company.updateCompany(id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update Company';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  updateCompanyStatus(_, { id, formData }) {
    return ApiClient.v1.company.updateCompanyStatus(id, formData);
  },
};

const mutations = {
  setSettings(state, data) {
    state.settings = data;
  },
  setCompanies(state, data) {
    state.companies = data;
  },
  setCompaniesMeta(state, data) {
    state.companiesMeta = data;
  },
  setCompany(state, data) {
    state.selectedCompany = data;
  },
  setActiveCompany(state, data) {
    state.activeCompany = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
