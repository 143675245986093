import { CaptureConsole } from '@sentry/integrations';
import axios from 'axios';
import ApiClient from '~/api';

const actionsEmployees = {
  async fetchEmployees({ commit, dispatch }, { query, dataFilter, cts = null }) {
    try {
      let data = [];
      let meta = {};
      if (dataFilter && dataFilter.by && dataFilter.value) {
        if (dataFilter.by === 'department') {
          ({ data, meta } = await ApiClient.v1.department.getDepartmentsEmployees(dataFilter.value, query, cts));
        } else if (dataFilter.by === 'position') {
          ({ data, meta } = await ApiClient.v1.department.getPositionsEmployees(dataFilter.value, query, cts));
        } else if (dataFilter.by === 'schedule') {
          ({ data, meta } = await ApiClient.v1.department.getPositionsSchedules(dataFilter.value, query, cts));
        } else if (dataFilter.by === 'job-category') {
          ({ data, meta } = await ApiClient.v1.department.getCategorysEmployees(dataFilter.value, query, cts));
        } else if (dataFilter.by === 'multi-department') {
          ({ data, meta } = await ApiClient.v1.department.getMultiDepartmentsEmployees(query, cts));
        }
      } else {
        ({ data, meta } = await ApiClient.v1.employee.getEmployees(query, cts));
      }

      commit('setEmployees', data);
      commit('setEmployeesMeta', meta);
      return data;
    } catch (e) {
      commit('setEmployees', []);
      commit('setEmployeesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchEmployee({ commit, dispatch }, uuid) {
    const employee = await dispatch('fetchEmployeeByUuid', uuid);
    commit('setActiveEmployee', employee);
  },
  async fetchEmployeeByUuid(_, uuid) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployee(uuid);
      return data;
    } catch (e) {
      return null;
    }
  },
  async fetchEmployeeByUuidGeneral(_, uuid) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeGeneral(uuid);
      return data;
    } catch (e) {
      return null;
    }
  },
  async createEmployee({ commit, dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEmployee(formData);
      commit('setEmployee', data);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }
      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployee({ commit, dispatch }, { uuid, idx = null, formData, admin = null }) {
    try {
      let ret = null;
      if (admin === false) {
        ret = await ApiClient.v1.employee.updateSelfEmployee(uuid, formData);
      } else {
        ret = await ApiClient.v1.employee.updateEmployee(uuid, formData);
      }

      if (idx !== null) commit('updateEmployee', { idx, data: ret.data });

      return { errors: null, data: ret.data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }
      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEmployee({ commit, dispatch }, { uuid, idx = null }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteEmployee(uuid);
      if (idx !== null) commit('deleteEmployee', idx);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }
      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEmployeeOtherData({ commit }, { uuid, params }) {
    try {
      const { data } = await ApiClient.v1.employee.getOtherData(uuid, { ...params, group: 'category' });
      commit('setActiveEmployeeOtherData', data);
      return data;
    } catch (e) {
      commit('setActiveEmployeeOtherData', null);
      return null;
    }
  },
  async patchEmployeeOtherData({ state, commit }, { ids, value }) {
    const data = JSON.parse(JSON.stringify(state.activeEmployeeOtherData[0]));

    const row = data.subcategories[ids.parent.idx].subcategories[ids.child.idx].records;
    if (row[ids.grandchild.idx]) row[ids.grandchild.idx].doc_value = value;
    else row[0] = { category_id: ids.child.id, doc_value: value };

    const newValue = { ...state.activeEmployeeOtherData, ...{ 0: data } };
    commit('setActiveEmployeeOtherData', newValue);
    return newValue[0];
  },
  async updateEmployeeOtherDataBulk({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateOtherDataBulk(uuid, { data: formData });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async changePhoto({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.changePhoto(uuid, formData);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async removePhoto({ dispatch }, uuid) {
    try {
      const { data } = await ApiClient.v1.employee.removePhoto(uuid);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Failed to Remove';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsNextOfKins = {
  async fetchNextOfKins({ commit }, { query, uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getNextOfKins(uuid, query);
      commit('setNextOfKins', data);
      commit('setNextOfKinsMeta', meta);

      return data;
    } catch (e) {
      commit('setNextOfKins', []);
      commit('setNextOfKinsMeta', {});

      return [];
    }
  },
  async fetchNextOfKin({ commit }, { uuid, id }) {
    try {
      const { data } = await ApiClient.v1.employee.getNextOfKin(uuid, id);
      commit('setActiveNextOfKin', data);
    } catch (e) {
      commit('setActiveNextOfKin', null);
    }
  },
  async createNextOfKin({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createNextOfKin(uuid, formData);
      // commit('setNextOfKin', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateNextOfKin({ dispatch }, { uuid, id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateNextOfKin(uuid, id, formData);
      // commit('updateNextOfKin', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteNextOfKin({ commit, dispatch }, { uuid, id, idx }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteNextOfKin(uuid, id);
      commit('deleteNextOfKin', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEducations = {
  async fetchEducations({ commit }, { query, uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEducations(uuid, query);
      commit('setEducations', data);
      commit('setEducationsMeta', meta);

      return data;
    } catch (e) {
      commit('setEducations', []);
      commit('setEducationsMeta', {});

      return [];
    }
  },
  async fetchEducation({ commit }, { uuid, id }) {
    try {
      const { data } = await ApiClient.v1.employee.getEducation(uuid, id);
      commit('setActiveEducation', data);
    } catch (e) {
      commit('setActiveEducation', null);
    }
  },
  async createEducation({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEducation(uuid, formData);
      // commit('setEducation', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEducation({ dispatch }, { uuid, id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEducation(uuid, id, formData);
      // commit('updateEducation', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEducation({ commit, dispatch }, { uuid, id, idx }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteEducation(uuid, id);
      commit('deleteEducation', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsJobs = {
  async fetchJobs({ commit }, { query, uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getJobs(uuid, query);
      commit('setJobs', data);
      commit('setJobsMeta', meta);

      return data;
    } catch (e) {
      commit('setJobs', []);
      commit('setJobsMeta', {});

      return [];
    }
  },
  async fetchJob({ commit }, { uuid, id }) {
    try {
      const { data } = await ApiClient.v1.employee.getJob(uuid, id);
      commit('setActiveJob', data);
    } catch (e) {
      commit('setActiveJob', null);
    }
  },
  async createJob({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createJob(uuid, formData);
      // commit('setJob', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateJob({ dispatch }, { uuid, id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateJob(uuid, id, formData);
      // commit('updateJob', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteJob({ commit, dispatch }, { uuid, id, idx }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteJob(uuid, id);
      commit('deleteJob', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEmergencyContacts = {
  async fetchContacts({ commit }, { uuid, query }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEmergencyContacts(uuid, query);
      commit('setContacts', data);
      commit('setContactsMeta', meta);

      return data;
    } catch (e) {
      commit('setContacts', []);
      commit('setContactsMeta', {});

      return [];
    }
  },
  async fetchContact({ commit }, { uuid, id }) {
    try {
      const { data } = await ApiClient.v1.employee.getEmergencyContact(uuid, id);
      commit('setActiveContact', data);
    } catch (e) {
      commit('setActiveContact', null);
    }
  },
  async createContact({ dispatch, commit }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEmergencyContact(uuid, formData);
      commit('setContact', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateContact({ dispatch, commit }, { uuid, id, idx, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEmergencyContact(uuid, id, formData);
      commit('updateContact', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteContact({ commit, dispatch }, { uuid, id, idx }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteEmergencyContact(uuid, id);
      commit('deleteContact', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsBankAccounts = {
  async fetchBankAccounts({ commit }, { query, uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getBankAccounts(uuid, query);
      commit('setBankAccounts', data);
      commit('setBankAccountsMeta', meta);

      return data;
    } catch (e) {
      commit('setBankAccounts', []);
      commit('setBankAccountsMeta', {});

      return [];
    }
  },
  async fetchBankAccount({ commit }, { uuid, id }) {
    try {
      const { data } = await ApiClient.v1.employee.getBankAccount(uuid, id);
      commit('setActiveBankAccount', data);
    } catch (e) {
      commit('setActiveBankAccount', null);
    }
  },
  async createBankAccount({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createBankAccount(uuid, formData);
      // commit('setBankAccount', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateBankAccount({ dispatch }, { uuid, id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateBankAccount(uuid, id, formData);
      // commit('updateBankAccount', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteBankAccount({ commit, dispatch }, { uuid, id, idx }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteBankAccount(uuid, id);
      commit('deleteBankAccount', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEmployeePositions = {
  async fetchEmployeeLastPosition({ dispatch }, { uuid, query }) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeLastPosition(uuid, query);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to load position information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEmployeePositions({ commit }, { query, uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEmployeePositions(uuid, query);
      commit('setEmployeePositions', data);
      commit('setEmployeePositionsMeta', meta);

      return data;
    } catch (e) {
      commit('setEmployeePositions', []);
      commit('setEmployeePositionsMeta', {});

      return [];
    }
  },
  async fetchEmployeePosition({ commit }, { uuid, id }) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeePosition(uuid, id);
      commit('setActiveEmployeePosition', data);
    } catch (e) {
      commit('setActiveEmployeePosition', null);
    }
  },
  async createEmployeePosition({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEmployeePosition(uuid, formData);
      // commit('setEmployeePosition', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployeePosition({ dispatch }, { uuid, id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEmployeePosition(uuid, id, formData);
      // commit('updateEmployeePosition', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEmployeePosition({ commit, dispatch }, { uuid, id, idx }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteEmployeePosition(uuid, id);
      commit('deleteEmployeePosition', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEmployeeSalaries = {
  async fetchEmployeeLastSalary({ dispatch }, uuid) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeLastSalary(uuid);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to load salary information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEmployeeSalaries({ commit }, { query, uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEmployeeSalaries(uuid, query);
      commit('setEmployeeSalaries', data);
      commit('setEmployeeSalariesMeta', meta);

      return data;
    } catch (e) {
      commit('setEmployeeSalaries', []);
      commit('setEmployeeSalariesMeta', {});

      return [];
    }
  },
  async fetchEmployeeSalary({ commit, dispatch }, { uuid, id, query = '' }) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeSalary(uuid, id, query);
      commit('setActiveEmployeeSalary', data);

      return { errors: null, data };
    } catch (e) {
      commit('setActiveEmployeeSalary', []);

      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to load salary information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  getEmployeeSalaryStatus(_, { uuid, query = '' }) {
    return ApiClient.v1.employee.getEmployeeSalaryStatus(uuid, query);
  },
  async createEmployeeSalary({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEmployeeSalary(uuid, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create Payroll';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: null };
    }
  },
  async updateEmployeeSalary({ dispatch }, { uuid, id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEmployeeSalary(uuid, id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update Payroll';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEmployeeSalary({ commit, dispatch }, { uuid, id, idx }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteEmployeeSalary(uuid, id);
      commit('deleteEmployeeSalary', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployeeSalaryStatus({ dispatch }, { uuid, id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEmployeeSalaryStatus(uuid, id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchCurrentSalary({ dispatch }, { uuid, queries }) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeSalaryCurrent(uuid, queries);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Unable to load available salary change reasons list';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsDataCategories = {
  async fetchDataCategories({ commit }) {
    try {
      const { data } = await ApiClient.v1.employee.getDataCategories({ as: 'tree' });
      commit('setDataCategories', data);

      return data;
    } catch (e) {
      commit('setDataCategories', []);

      return [];
    }
  },
  async fetchDataCategory({ commit }, { id }) {
    try {
      const { data } = await ApiClient.v1.employee.getDataCategory(id);
      commit('setActiveDataCategory', data);
    } catch (e) {
      commit('setActiveDataCategory', null);
    }
  },
  async createDataCategory({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createDataCategory(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateDataCategory({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateDataCategory(id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteDataCategory({ commit, dispatch }, { id, idx }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteDataCategory(id);
      commit('deleteDataCategory', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateDataCategoryBulkParent({ commit, dispatch }, formData) {
    try {
      const { data } = await ApiClient.v1.employee.updateDataCategoryBulkParent(formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsRoles = {
  async assignRoles({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.assignRoles(uuid, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to assign roles';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async unassignRole({ dispatch }, { uuid, role }) {
    try {
      const { data } = await ApiClient.v1.employee.unassignRole(uuid, role);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to remove role';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async assignPermissionGroup({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.assignPermissionGroup(uuid, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to assign roles';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async unassignPermissionGroup({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.unassignPermissionGroup(uuid, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to remove role';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEmployeeRoles({ dispatch, commit }, { uuid }) {
    try {
      const { data } = await ApiClient.v1.employee.getRoles(uuid);
      commit('setEmployeeRoles', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }
      commit('setEmployeeRoles', []);

      const caption = 'Failed to load employee role';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEmployeeSchedules = {
  async fetchEmployeeLastSchedule({ dispatch }, uuid) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeLastSchedule(uuid);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to load schedule information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEmployeeSchedules({ commit }, { query, uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEmployeeSchedules(uuid, query);
      commit('setEmployeeSchedules', data);
      commit('setEmployeeSchedulesMeta', meta);

      return data;
    } catch (e) {
      commit('setEmployeeSchedules', []);
      commit('setEmployeeSchedulesMeta', {});

      return [];
    }
  },
  async fetchEmployeeSchedule({ commit }, { uuid, id, query }) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeSchedule(uuid, id, query);
      commit('setActiveEmployeeSchedule', data);
    } catch (e) {
      commit('setActiveEmployeeSchedule', null);
    }
  },
  async createEmployeeSchedule({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEmployeeSchedule(uuid, formData);
      // commit('setEmployeeSchedule', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployeeSchedule({ dispatch }, { uuid, id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEmployeeSchedule(uuid, id, formData);
      // commit('updateEmployeeSchedule', { idx, data });

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEmployeeSchedule({ commit, dispatch }, { uuid, id, idx }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteEmployeeSchedule(uuid, id);
      commit('deleteEmployeeSchedule', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEmployeeCustomSchedules = {
  async fetchEmployeeLastCustomSchedule({ dispatch }, id) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeLastCustomSchedule(id);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to load schedule information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEmployeeCustomSchedules({ commit }, { query, id }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEmployeeCustomSchedules(id, query);
      commit('setEmployeeCustomSchedules', data);
      commit('setEmployeeCustomSchedulesMeta', meta);

      return data;
    } catch (e) {
      commit('setEmployeeCustomSchedules', []);
      commit('setEmployeeCustomSchedulesMeta', {});

      return [];
    }
  },
  async fetchEmployeeCustomSchedule({ commit }, { id, cid }) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeCustomSchedule(id, cid);
      commit('setActiveEmployeeCustomSchedule', data);
    } catch (e) {
      commit('setActiveEmployeeCustomSchedule', null);
    }
  },
  async createEmployeeCustomSchedule({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEmployeeCustomSchedule(id, formData);
      // commit('setEmployeeCustomSchedule', data);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployeeCustomSchedule({ dispatch }, { id, cid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEmployeeCustomSchedule(id, cid, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEmployeeCustomSchedule({ commit, dispatch }, { id, cid, idx }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteEmployeeCustomSchedule(id, cid);
      commit('deleteEmployeeCustomSchedule', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEmployeeShiftSchedules = {
  async fetchEmployeeLastShiftSchedule({ dispatch }, id) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeLastShiftSchedule(id);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to load schedule information';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEmployeeShiftSchedules({ commit }, { query, id }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEmployeeShiftSchedules(id, query);
      commit('setEmployeeShiftSchedules', data);
      commit('setEmployeeShiftSchedulesMeta', meta);

      return data;
    } catch (e) {
      commit('setEmployeeShiftSchedules', []);
      commit('setEmployeeShiftSchedulesMeta', {});

      return [];
    }
  },
  async fetchEmployeeShiftSchedule({ commit }, { id, cid }) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeShiftSchedule(id, cid);
      commit('setActiveEmployeeShiftSchedule', data);
    } catch (e) {
      commit('setActiveEmployeeShiftSchedule', null);
    }
  },
  async fetchEmployeeShiftScheduleByYearMonth({ commit }, { id, year, month }) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeShiftScheduleByYearMonth(id, year, month);
      commit('setActiveEmployeeShiftSchedule', data);
    } catch (e) {
      commit('setActiveEmployeeShiftSchedule', null);
    }
  },
  async createEmployeeShiftSchedule({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEmployeeShiftSchedule(id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployeeShiftSchedule({ dispatch }, { id, cid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEmployeeShiftSchedule(id, cid, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEmployeeShiftSchedule({ commit, dispatch }, { id, cid, idx }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteEmployeeShiftSchedule(id, cid);
      commit('deleteEmployeeShiftSchedule', idx);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEmployeePayslips = {
  // Employee Payslips for each year
  async fetchEmployeePayslips({ commit, dispatch }, { uuid, query }) {
    try {
      const { data, employee } = await ApiClient.v1.employee.getEmployeePayslips(uuid, query);
      commit('setEmployeePayslips', data);
      commit('setActiveEmployee', employee);

      return data;
    } catch (e) {
      commit('setEmployeePayslips', []);
      commit('setActiveEmployee', {});

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  changePasswordPayslips(_, { uuid, formData }) {
    return ApiClient.v1.employee.changePasswordPayslips(uuid, formData);
  },
  async getOTP(_, { uuid }) {
    return ApiClient.v1.employee.getOTP(uuid);
  },
};

const actionsEmployeeFinanceDocs = {
  // Employee Finance Documents
  async fetchEmployeeFinanceDocs({ commit, dispatch }, { uuid, query }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEmployeeFinanceDocs(uuid, query);
      commit('setEmployeeFinanceDocs', data);
      commit('setEmployeeFinanceDocMeta', meta);

      return data;
    } catch (e) {
      commit('setEmployeeFinanceDocs', []);
      commit('setEmployeeFinanceDocMeta', {});

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
};

const actionsEmployeeActivates = {
  async fetchHistories({ commit }, { uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getHistories(uuid);
      commit('setEmployeeHistories', data);

      return data;
    } catch (e) {
      commit('setEmployeeHistories', []);

      return [];
    }
  },
  async activateEmployee({ commit, dispatch }, { uuid, formData }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.activateEmployee(uuid, formData);
      
      return { errors: null, data };
    } catch (e) {
      const caption = `Failed to ${formData.action} employee`;
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return { errors: e, data: [] };
    }
  },
};

const actionsExportEmployee = {
  async fetchExports({ commit, dispatch }, { query, cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getExports(query, cts);

      commit('setExports', data);
      commit('setExportMeta', meta);
      return data;
    } catch (e) {
      commit('setExports', []);
      commit('setExportMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchExportStatusList({ commit }) {
    try {
      const { data } = await ApiClient.v1.employee.getExportStatus();
      commit('setExportStatuses', data);
      return [];
    } catch (e) {
      commit('setExportStatuses', []);
      return [];
    }
  },
  fetchExportById(_, id) {
    return ApiClient.v1.employee.getExportById(id);
  },
  async fetchExportEmployeesById({ commit, dispatch }, { id, query = '', cts = null }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getExportEmployeesById(id, query, cts);
      commit('setExportEmployees', data);
      commit('setExportEmployeesMeta', meta);
      return data;
    } catch (e) {
      commit('setExportEmployees', []);
      commit('setExportEmployeesMeta', {});

      // Ignore if it is a canceled request
      if (axios.isCancel(e)) {
        return null;
      }

      const caption = 'Failed to Load Data';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return [];
    }
  },
  async fetchExportHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.employee.getExportHistoryById(id);
      commit('setExportHistories', data);
      return data;
    } catch (e) {
      commit('setExportHistories', []);
      return [];
    }
  },
  async exportEmployee({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.employee.exportEmployee(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to export employee';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEmployeeTrainings = {
  async fetchEmployeeTrainings({ commit }, { query, uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEmployeeTrainings(uuid, query);
      commit('setEmployeeTrainings', data);
      commit('setEmployeeTrainingsMeta', meta);

      return data;
    } catch (e) {
      commit('setEmployeeTrainings', []);
      commit('setEmployeeTrainingsMeta', {});

      return [];
    }
  },
  fetchEmployeeTrainingById({ commit }, { uuid, id }) {
    return ApiClient.v1.employee.getEmployeeTrainingById(uuid, id);
  },
  async createEmployeeTraining({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEmployeeTraining(uuid, formData);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Failed to Create';
      let message = null;

      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 409) && e.response.data && e.response.data.message) {
        /* eslint-disable */
        message = e.response.data.message;
      }

      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployeeTraining({ dispatch }, { uuid, id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEmployeeTraining(uuid, id, formData);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Failed to Update';
      let message = null;

      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 409) && e.response.data && e.response.data.message) {
        /* eslint-disable */
        message = e.response.data.message;
      }

      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEmployeeTraining({ commit, dispatch }, { uuid, id }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteEmployeeTraining(uuid, id);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEmployeeBondingContract({ commit }, { query, uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEmployeeBondingContract(uuid, query);
      commit('setEmployeeBondingContracts', data);
      commit('setEmployeeBondingContractsMeta', meta);

      return data;
    } catch (e) {
      commit('setEmployeeBondingContracts', []);
      commit('setEmployeeBondingContractsMeta', {});

      return [];
    }
  },
  fetchEmployeeBondingContractById({ commit }, { uuid, id }) {
    return ApiClient.v1.employee.getEmployeeBondingContractById(uuid, id);
  },
  async createEmployeeBondingContract({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEmployeeBondingContract(uuid, formData);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Failed to Create';
      let message = null;

      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 409) && e.response.data && e.response.data.message) {
        /* eslint-disable */
        message = e.response.data.message;
      }

      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployeeBondingContract({ dispatch }, { uuid, id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEmployeeBondingContract(uuid, id, formData);

      return { errors: null, data };
    } catch (e) {
      const caption = 'Failed to Update';
      let message = null;

      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 409) && e.response.data && e.response.data.message) {
        /* eslint-disable */
        message = e.response.data.message;
      }

      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEmployeeBondingContract({ commit, dispatch }, { uuid, id }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteEmployeeBondingContract(uuid, id);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEmployeeWarnings = {
  // Warning Type
  async fetchWarningTypeList({ commit }, { query, customCompanyId } = {}) {
    try {
      const { data } = await ApiClient.v1.employee.fetchWarningType(query, customCompanyId);
      commit('setWarningTypes', data);
      return data;
    } catch (e) {
      commit('setWarningTypes', []);
      return [];
    }
  },
  getWarningTypeById(_, id) {
    return ApiClient.v1.employee.getWarningTypeById(id);
  },
  createWarningType(_, formData) {
    return ApiClient.v1.employee.createWarningType(formData);
  },
  updateWarningType(_, { id, formData }) {
    return ApiClient.v1.employee.updateWarningType(id, formData);
  },
  deleteWarningType(_, id) {
    return ApiClient.v1.employee.deleteWarningType(id);
  },

  // Warning records
  async fetchEmployeeWarnings({ commit }, { query, uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEmployeeWarnings(uuid, query);
      commit('setEmployeeWarnings', data);
      commit('setEmployeeWarningsMeta', meta);

      return data;
    } catch (e) {
      commit('setEmployeeWarnings', []);
      commit('setEmployeeWarningsMeta', {});

      return [];
    }
  },
  fetchEmployeeWarningById({ commit }, { uuid, id }) {
    return ApiClient.v1.employee.getEmployeeWarningById(uuid, id);
  },
  async createEmployeeWarning({ dispatch }, { uuid, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEmployeeWarning(uuid, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Create';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployeeWarning({ dispatch }, { uuid, id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEmployeeWarning(uuid, id, formData);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Update';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteEmployeeWarning({ commit, dispatch }, { uuid, id }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteEmployeeWarning(uuid, id);

      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to Delete';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEmployeeTransfers = {
  async fetchEmployeeTransfers({ commit }, { query = '' }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.fetchEmployeeTransfers(query);
      commit('setEmployeeTransfers', data);
      commit('setEmployeeTransfersMeta', meta);
      return data;
    } catch (e) {
      commit('setEmployeeTransfers', []);
      commit('setEmployeeTransfersMeta', {});
      return [];
    }
  },
  async fetchEmployeeTransferStatusList({ commit }) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeTransferStatus();
      commit('setEmployeeTransferStatuses', data);
      return [];
    } catch (e) {
      commit('setEmployeeTransferStatuses', []);
      return [];
    }
  },
  fetchEmployeeTransferById(_, id) {
    return ApiClient.v1.employee.getEmployeeTransferById(id);
  },
  fetchEmployeeTransferByMasterData(_, { id, query }) {
    return ApiClient.v1.employee.getEmployeeTransferByMasterData(id, query);
  },
  async fetchEmployeeTransferHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeeTransferHistoryById(id);
      commit('setEmployeeTransferHistories', data);
      return data;
    } catch (e) {
      commit('setEmployeeTransferHistories', []);
      return [];
    }
  },
  async createEmployeeTransfer({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createEmployeeTransfer(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to create employee transfer';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateEmployeeTransfer({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateEmployeeTransfer(id, formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to update employee transfer';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async retryEmployeeTransfer({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.employee.retryEmployeeTransfer(id);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to retry employee transfer';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async fetchEmployeeFiles({ commit }, { query, uuid }) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getEmployeeFiles(uuid, query);
      commit('setEmployeeFiles', data);
      commit('setEmployeeFilesMeta', meta);

      return data;
    } catch (e) {
      commit('setEmployeeFiles', []);
      commit('setEmployeeFilesMeta', {});

      return [];
    }
  },
  async getDraftEmployeeTransfer({ commit }) {
    try {
      const { data } = await ApiClient.v1.employee.getDraftEmployeeTransfer();
      commit('setDraftEmployeeTransfer', data);

      return data;
    } catch (e) {
      commit('setDraftEmployeeTransfer', []);

      return [];
    }
    // return ApiClient.v1.employee.getDraftEmployeeTransfer();
  },
};

const actionsEmployeePayroll = {
  async fetchTaxStatuses({ commit }) {
    try {
      const { data } = await ApiClient.v1.employee.getTaxStatuses();
      commit('setTaxStatuses', data);

      return data;
    } catch (e) {
      commit('setTaxStatuses', []);
      return [];
    }
  },
  async fetchEmployeePayrollInfo({ commit, dispatch }, { uuid, query }) {
    try {
      const { data } = await ApiClient.v1.employee.getEmployeePayrollInfo(uuid, query);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to get employee payroll info';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actionsEmployeeWorkArrangements = {
  async fetchWorkArrangementList({ commit }, { query } = {}) {
    try {
      const { data, meta } = await ApiClient.v1.employee.getWorkArrangements(query);
      commit('setWorkArrangements', data);
      commit('setWorkArrangementsMeta', meta);

      return data;
    } catch (e) {
      commit('setWorkArrangements', []);
      commit('setWorkArrangementsMeta', {});

      return [];
    }
  },
  getWorkArrangementById(_, id) {
    return ApiClient.v1.employee.getWorkArrangementById(id);
  },
  async createWorkArrangement({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.employee.createWorkArrangement(formData);
      return { errors: null, data };
    } catch (e) {
      const caption = 'Failed to create work arrangement';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });

      return { errors: e, data: [] };
    }
  },
  async updateWorkArrangement({ dispatch }, { id, formData }) {
    try {
      const { data } = await ApiClient.v1.employee.updateWorkArrangement(id, formData);
      return { errors: null, data };
    } catch (e) {
      const caption = 'Failed to update work arrangement';
      const message = e.response.status === 400 ? e.response.data.message : null;
      dispatch('error/catchError', { error: e, caption, message }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async updateStatusWorkArrangement({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.employee.updateStatusWorkArrangement(id);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to update status work arrangement';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
  async deleteWorkArrangement({ dispatch }, { id }) {
    try {
      const { data } = await ApiClient.v1.employee.deleteWorkArrangement(id);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Failed to delete work arrangement';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const actions = {
  ...actionsBankAccounts,
  ...actionsEducations,
  ...actionsEmergencyContacts,
  ...actionsEmployeePositions,
  ...actionsEmployeeSchedules,
  ...actionsEmployeeCustomSchedules,
  ...actionsEmployeeShiftSchedules,
  ...actionsEmployeeSalaries,
  ...actionsEmployees,
  ...actionsJobs,
  ...actionsNextOfKins,
  ...actionsDataCategories,
  ...actionsRoles,
  ...actionsEmployeePayslips,
  ...actionsEmployeeFinanceDocs,
  ...actionsEmployeeActivates,
  ...actionsExportEmployee,
  ...actionsEmployeeTrainings,
  ...actionsEmployeeWarnings,
  ...actionsEmployeeTransfers,
  ...actionsEmployeePayroll,
  ...actionsEmployeeWorkArrangements,
};

export default actions;
